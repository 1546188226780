var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: "fill-current text-".concat(_vm.color),
    attrs: {
      "height": "".concat(_vm.height, "px"),
      "width": "".concat(_vm.width, "px"),
      "viewBox": "0 0 19 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_2257_7592)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M0.767578 3.69022C0.767578 2.06939 2.08152 0.75544 3.70236 0.75544H14.1575C15.7784 0.75544 17.0923 2.06939 17.0923 3.69022V7.41444C18.2477 8.24705 18.9999 9.6043 18.9999 11.1372C18.9999 12.6702 18.2477 14.0274 17.0923 14.86V16.3098C17.0923 17.9306 15.7784 19.2446 14.1575 19.2446H3.70236C2.08152 19.2446 0.767578 17.9306 0.767578 16.3098V3.69022ZM15.6249 8.27582V8.26274C15.2527 8.10579 14.8436 8.01903 14.4143 8.01903C12.6922 8.01903 11.2961 9.4151 11.2961 11.1372C11.2961 12.8594 12.6922 14.2554 14.4143 14.2554C16.1365 14.2554 17.5325 12.8594 17.5325 11.1372C17.5325 9.8562 16.76 8.75559 15.6555 8.27582H15.6249ZM15.6249 3.69022V6.71313C15.2393 6.60784 14.8334 6.55164 14.4143 6.55164C11.8818 6.55164 9.82872 8.60468 9.82872 11.1372C9.82872 13.6698 11.8818 15.7228 14.4143 15.7228C14.8334 15.7228 15.2393 15.6666 15.6249 15.5613V16.3098C15.6249 17.1202 14.9679 17.7772 14.1575 17.7772H3.70236C2.89194 17.7772 2.23497 17.1202 2.23497 16.3098V3.69022C2.23497 2.8798 2.89194 2.22283 3.70236 2.22283H14.1575C14.9679 2.22283 15.6249 2.8798 15.6249 3.69022ZM12.0298 3.83696H5.86676V5.30435H12.0298V3.83696Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M16.0473 12.6235C16.2264 12.4444 16.2264 12.1541 16.0473 11.975L15.1524 11.08L16.0473 10.1851C16.2264 10.006 16.2264 9.7157 16.0473 9.53662C15.8682 9.35754 15.5779 9.35754 15.3988 9.53662L14.5039 10.4315L13.583 9.51067C13.4039 9.33159 13.1136 9.33159 12.9345 9.51067C12.7554 9.68974 12.7554 9.98009 12.9345 10.1592L13.8554 11.08L12.9345 12.0009C12.7554 12.18 12.7554 12.4704 12.9345 12.6494C13.1136 12.8285 13.4039 12.8285 13.583 12.6494L14.5039 11.7285L15.3988 12.6235C15.5779 12.8026 15.8682 12.8026 16.0473 12.6235Z"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_2257_7592"
    }
  }, [_c('rect', {
    attrs: {
      "width": "18.7826",
      "height": "18.7826",
      "fill": "white",
      "transform": "translate(0.217285 0.608704)"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }